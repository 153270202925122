import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fshared%2Fcomponents%2FAddressAutocomplete%2FAddressAutoCompleteStreetNumber%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA61WyW7bMBS85ysIBAUSoDKs3WEuhZEG6Bf0WNASJbGRSIKkYjlF%2Fr0gtVKLExS1DzaXN5w3nPek3S%2FXoyrP%2BB78uQGAM0kUYRQCgUukyCt%2BvAHgzSE0xQ0E7v7x5v1m18e4n40B4ExSVejB%2FosF4RmIlEheogsElFFsrfsfrAdmHZUkpw5RuJIQSCWwSorHaVxW4kZP6F8nJQInLeOElXVF9UqOOASvSNw5zvlwoH7sk3s9XyGREwrBHqBaMevs8Er6W%2FlGJqbEmYLgwA2nEQCdJCtrZQAU4xAEesckOjbRJ5S85ILVNHUSVjIBwe2z%2Bei4ExMpFhC4vAGSlSQFt0f%2F6B%2B9cdERKCW17OBbUSBwzV9GlZOhipQXCH5QhcUwK8kbhsDtSBeY5IWCIOwwOEpTQnMIPI83OrMefEuIGGYsqeU%2FJeQ%2BuU%2FuUS%2ByWpWE4hVjxDtZsPNPJCiheXtKB%2BSNQN%2FDKAgjK%2B6wNFSCeyEWfjKu6TIdnNIOGqfXyEwwjhKiLv3oFYusZGcICpKm2DhQCUR7J7RYYOfLr31kOxhx9dhmvsi4ZxTwZsnKx5XFy7XAHq7JMN4XnF7GuruWN7pQsacUdSG%2Fa6lIdnESRhWmanr04DP3gTfA99oAhRvlGLLTvVNB5xYDO1caexZEYUdylBgTnQXitg4QJbqmt3waeqEXzqSDhb7erYjYj%2F3YsyLQ%2Fy3ryBZ%2BvKC27exHszqmyxysEm7nvKu9aSK3xhz6VfhB0Z9MokktpGbGGbE6zKLvjJeta9aNZt3wdF3o51B%2FZxFj05m1js9CJPYD6VSy5GXI4NwZ%2BcTKdKLyiSnFqmU7T1s5Fve0gd514DlK681BK917W71WikNoflZ0ZudDqBbFGQ7uS7M%2F9VuFU4KATATGFCCagruKUGe4cC%2Fgzb2BnL0lDKbwo33L6326yes2bXSclaax%2Fchu%2B1x3XLzvjtt6Uls0%2FDUakxeKVR7bLxXXOHb8Ai9YkSPoeMxefGa74m7XvH%2FOnkVWzEMXM9ZWrBtpvEICdVtNaUdev%2BP9L9MwSgk0CgAA%22%7D"
export var autoComplete = '_12ntgfp0';
export var autoCompleteStreetNumber = '_12ntgfp1';
export var formButton = '_12ntgfp9';
export var formDesktop = {abTestVersionControl:'_12ntgfp2',abTestVersionB:'_12ntgfp3'};
export var formInput = '_12ntgfp7';
export var formInputWrapper = '_12ntgfp5';
export var formLabel = '_12ntgfp6';
export var formMobile = '_12ntgfp4';
export var formWarning = '_12ntgfp8';
export var suggestionsGoogleLogo = '_12ntgfpf';
export var suggestionsGoogleLogoWrapper = '_12ntgfpe';
export var suggestionsItem = '_12ntgfpb';
export var suggestionsItemCity = '_12ntgfpd';
export var suggestionsItemStreet = '_12ntgfpc';
export var suggestionsList = '_12ntgfpa';