import { useEffect, useRef } from "react"
import { CommonAutocomplete } from "@app/shared/types/website-pages"
import useTranslation from "next-translate/useTranslation"
import { formInput, formInputWrapper, formLabel, formWarning } from "./styles.css"
import { Body } from "@casavo/habitat"

type Props = {
  showStreetNumberInputWarning: boolean
  setStreetNumber: React.Dispatch<React.SetStateAction<string>>
  setShowStreetNumberInputWarning: React.Dispatch<React.SetStateAction<boolean>>
  streetNumber: string
}

export const StreetNumberInput: React.FC<Props> = ({
  showStreetNumberInputWarning,
  streetNumber,
  setStreetNumber,
  setShowStreetNumberInputWarning,
}) => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)
  const { placeholder_streetnumber, missing_streetnumber } = t<CommonAutocomplete>(
    "common-new:autocomplete",
    {},
    { returnObjects: true }
  )

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  return (
    <div className={formInputWrapper}>
      <span className={formLabel}>
        <Body noMargin size="s">
          {placeholder_streetnumber}
        </Body>
      </span>
      <input
        ref={inputRef}
        autoComplete="off"
        className={`${formInput} ${showStreetNumberInputWarning ? "showWarning" : ""}`}
        name="street-number"
        type="text"
        inputMode="numeric"
        value={streetNumber}
        onChange={(e) => {
          setShowStreetNumberInputWarning(false), setStreetNumber(e.target.value)
        }}
      />
      <div className={`${formWarning} ${showStreetNumberInputWarning ? "showWarning" : ""}`}>
        <Body noMargin size="s">
          <img alt={"info"} height={12} src={`/images/icons/info-warning.svg`} width={12} />
          {missing_streetnumber}
        </Body>
      </div>
    </div>
  )
}
